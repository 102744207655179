import request from "@/utils/request";

// 收运企业构成
export function collector_stat(areaCode, query) {
  return request({
    url: "/bi/collector/stat/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运企业油渣收运量
export function order(areaCode, query) {
  return request({
    url: "/bi/collector/stat/order/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运中转站
export function transfer(areaCode, query) {
  return request({
    url: "/bi/collector/stat/transfer/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运人员
export function employee(areaCode, query) {
  return request({
    url: "/bi/collector/stat/employee/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运车辆
export function vehicle(areaCode, query) {
  return request({
    url: "/bi/collector/stat/vehicle/" + areaCode,
    method: "GET",
    params: query
  });
}

// 折线图
export function trend(areaCode, query) {
  return request({
    url: "/bi/collector/stat/order/trend/" + areaCode,
    method: "GET",
    params: query
  });
}

// 列表
export function list(areaCode, query) {
  return request({
    url: "/bi/collector/stat/list/" + areaCode,
    method: "GET",
    params: query
  });
}

<template>
  <div>
    <div class="time-flex">
      日期范围<el-date-picker
        v-model="timeValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="small"
        value-format="yyyy-MM-dd"
        @change="timeChange"
      >
      </el-date-picker>
      <div
        class="btn-time"
        :class="colorActive == 1 ? '' : 'btn-time-active'"
        @click="monthClick(1)"
      >
        近一月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 3 ? '' : 'btn-time-active'"
        @click="monthClick(3)"
      >
        近三月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 12 ? '' : 'btn-time-active'"
        @click="monthClick(12)"
      >
        近一年
      </div>
    </div>

    <div class="mying-box">
      <div class="mying-box-title">收运统计</div>
      <div>
        <div class="flex margin-b-10">
          <div class="mying-box-small margin-r-10" style="flex:1">
            <div v-if="show1">
              <div id="collector-chart1" class="chart_height"></div>
            </div>
            <div class="empty-text" v-else>暂无数据</div>
            <div class="text-center">收运企业构成</div>
          </div>
          <div class="mying-box-small  margin-r-10" style="flex:1">
            <div v-if="show2">
              <div id="collector-chart2" class="chart_height"></div>
            </div>
            <div class="empty-text" v-else>暂无数据</div>
            <div class="text-center">收运企业油渣收运量</div>
          </div>
          <div class="mying-box-small  margin-r-10" style="flex:1">
            <div v-if="show3">
              <div id="collector-chart3" class="chart_height"></div>
            </div>
            <div class="empty-text" v-else>暂无数据</div>
            <div class="text-center">收运中转站</div>
          </div>
          <div class="mying-box-small  margin-r-10" style="flex:1">
            <div id="collector-chart4" style="width:100%;height:90%"></div>
            <div class="text-center">收运人员</div>
          </div>
          <div class="mying-box-small" style="flex:1">
            <div id="collector-chart5" style="width:100%;height:90%"></div>
            <div class="text-center">收运车辆</div>
          </div>
        </div>
        <div class="mying-box-small">
          <div v-if="show6">
            <div id="collector-chart6" class="chart_height"></div>
          </div>
          <div class="empty-text" v-else>暂无数据</div>
        </div>
      </div>
    </div>
    <div class="mying-box">
      <!-- <div v-if="tableData.list.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="收运类型">
            <el-select v-model="formSelect.type" clearable placeholder="请选择">
              <el-option label="油脂" value="1"></el-option>
              <el-option label="固渣" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收运状态">
            <el-select
              v-model="formSelect.status"
              clearable
              placeholder="请选择"
            >
              <el-option label="运输中" value="1"></el-option>
              <el-option label="已完成" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect.seach"
              clearable
              placeholder="请输入关键词"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData.list" style="width: 100%">
          <el-table-column prop="name" label="企业名称" min-width="120">
          </el-table-column>
          <el-table-column prop="corporate" label="法人代表"> </el-table-column>
          <el-table-column
            prop="detailAddress"
            label="详细地址"
            min-width="120"
          >
          </el-table-column>
          <el-table-column prop="contactTel" label="联系电话">
          </el-table-column>
          <el-table-column prop="collectType" label="收运类型">
          </el-table-column>
          <el-table-column prop="transferCount" label="中转站">
          </el-table-column>
          <el-table-column prop="employeeCount" label="收运人员/人">
          </el-table-column>
          <el-table-column prop="merchantCount" label="收运商户/家">
          </el-table-column>
          <el-table-column prop="status" label="系统状态"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope" prop="collectorCode">
              <i
                class="el-icon-view"
                @click="handleEdit(scope.$index, scope.row)"
                style="cursor: pointer;"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData.total"
            :current-page="tableData.currentpage"
            :page-size="tableData.pagesize"
            @current-change="handleCurrentChange"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  collector_stat,
  order,
  transfer,
  employee,
  vehicle,
  trend,
  list
} from "@/api/collector_statistics";
export default {
  data() {
    return {
      query: {
        offsetMonth: 1,
        beginDate: "",
        endDate: ""
      },
      areaCode: "",
      timeValue: "",
      colorActive: 1,
      show1: false,
      show2: false,
      show3: false,
      show6: false,
      formSelect: {
        type: "",
        status: "",
        seach: ""
      },
      tableData: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      }
    };
  },
  mounted() {
    this.monthClick(1);
  },
  methods: {
    // 选择日期范围
    timeChange(e) {
      console.log(e);
      if (e != null) {
        this.query = {
          beginDate: e[0],
          endDate: e[1]
        };
        this.colorActive = 0;
        this.get_collector_stat();
        this.getorder();
        this.gettransfer();
        this.getemployee();
        this.getvehicle();
        this.gettrend();
        this.getList();
      } else {
        this.colorActive = 1;
        this.monthClick(1);
      }
    },
    monthClick(data) {
      this.colorActive = data;
      this.timeValue = "";
      this.query = {
        offsetMonth: data
      };
      this.get_collector_stat();
      this.getorder();
      this.gettransfer();
      this.getemployee();
      this.getvehicle();
      this.gettrend();
      this.getList();
    },
    // 收运企业构成
    get_collector_stat() {
      collector_stat(this.areaCode, this.query).then(res => {
        // console.log(res);

        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { name: item.collectType, value: item.number };
          });
          this.show1 = true;
          this.$nextTick(() => {
            this.Chart1(data);
          });
        } else {
          this.show1 = false;
        }
      });
    },
    // 收运企业油渣收运量
    getorder() {
      order(this.areaCode, this.query).then(res => {
        // console.log(res);

        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { name: item.collectType, value: item.weight };
          });
          this.show2 = true;
          this.$nextTick(() => {
            this.Chart2(data);
          });
        } else {
          this.show2 = false;
        }
      });
    },
    // 收运中转站
    gettransfer() {
      transfer(this.areaCode, this.query).then(res => {
        // console.log(res);

        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { name: item.collectType, value: item.number };
          });
          this.show3 = true;
          this.$nextTick(() => {
            this.Chart3(data);
          });
        } else {
          this.show3 = false;
        }
      });
    },
    // 收运人员
    getemployee() {
      employee(this.areaCode, this.query).then(res => {
        // console.log(res);

        let data = res.data.map(item => {
          return { name: item.collectType, value: item.number };
        });
        this.Chart4(data);
      });
    },
    // 收运车辆
    getvehicle() {
      vehicle(this.areaCode, this.query).then(res => {
        // console.log(res);

        let data = res.data.map(item => {
          return { name: item.collectType, value: item.number };
        });
        this.Chart5(data);
      });
    },
    gettrend() {
      trend(this.areaCode, this.query).then(res => {
        // console.log(res);

        if (res.data.length != 0) {
          let xData = [],
            data1 = [],
            data2 = [];
          res.data.forEach(element => {
            xData.push(element.days);
            data1.push(element.oilWeight);
            data2.push(element.solidResidueWeight);
          });
          this.show6 = true;
          this.$nextTick(() => {
            this.Chart6(xData, data1, data2);
          });
        } else {
          this.show6 = false;
        }
      });
    },
    getList() {
      list(this.areaCode, this.query).then(res => {
        // console.log(res);
        // return res;
        this.tableData.list = res.rows;
        this.tableData.total = res.total;
      });
    },
    handleEdit(index, row) {
      // console.log(index, row);
      this.$router.push({
        path: "/collDetails",
        query: { id: row.collectorCode }
      });
    },
    handleCurrentChange(val) {
      this.tableData.currentpage = val;
      this.getList();
    },
    search() {
      this.query.collectType = this.formSelect.type;
      this.query.sysStatus = this.formSelect.status;
      this.query.condition = this.formSelect.seach;
      this.getList();
    },
    Chart1(data) {
      let collectorChart1 = this.$echarts.init(
        document.getElementById("collector-chart1")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          }
          // icon: "circle"
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            name: "收运企业构成",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{c}家\n{d}%",
              fontSize: 14,
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: data
          }
        ]
      };
      // 绘制图表
      collectorChart1.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart1.resize();
      });
    },
    Chart2(data) {
      let collectorChart2 = this.$echarts.init(
        document.getElementById("collector-chart2")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          }
          // icon: "circle"
        },
        series: [
          {
            name: "收运企业油渣收运量",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{c}kg\n{d}%",
              fontSize: 14,
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: data
          }
        ]
      };
      // 绘制图表
      collectorChart2.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart2.resize();
      });
    },
    Chart3(data) {
      let collectorChart3 = this.$echarts.init(
        document.getElementById("collector-chart3")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          }
          // icon: "circle"
        },
        series: [
          {
            name: "收运中转站",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{c}家\n{d}%",
              fontSize: 14,
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: data
          }
        ]
      };
      // 绘制图表
      collectorChart3.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart3.resize();
      });
    },
    Chart4(data) {
      let collectorChart4 = this.$echarts.init(
        document.getElementById("collector-chart4")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          }
          // icon: "circle"
        },
        series: [
          {
            name: "收运人员",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{c}人\n{d}%",
              fontSize: 14,
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: data
          }
        ]
      };
      // 绘制图表
      collectorChart4.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart4.resize();
      });
    },
    Chart5(data) {
      let collectorChart5 = this.$echarts.init(
        document.getElementById("collector-chart5")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          }
          // icon: "circle"
        },
        series: [
          {
            name: "收运车辆",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{c}辆\n{d}%",
              fontSize: 14,
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: data
          }
        ]
      };
      // 绘制图表
      collectorChart5.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart5.resize();
      });
    },
    Chart6(xData, data1, data2) {
      let collectorChart1 = this.$echarts.init(
        document.getElementById("collector-chart6")
      );
      let option = {
        // backgroundColor: "#fff",

        legend: {
          show: true,
          icon: "circle",
          top: "5%",
          // itemWidth: 6,
          // itemHeight: 6,
          // itemGap: 25,
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          containLabel: true,
          top: "20%",
          left: "2%",
          right: "2%",
          bottom: "2%"
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLine: {
              lineStyle: {
                color: "rgba(48, 216, 252, 0.2)"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "rgba(48, 216, 252, 1)"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(48, 216, 252, 0.2)"
              }
            },
            axisLabel: {
              textStyle: {
                color: "rgba(48, 216, 252, 1)"
              }
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: "油收运",
            type: "line",
            data: data1,
            symbolSize: 6,
            symbol: "circle",
            // smooth: true,
            lineStyle: {
              color: "#fe9a8b"
            },
            itemStyle: {
              normal: {
                color: "#fe9a8b",
                borderColor: "#fe9a8b"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fe9a8bb3"
                },
                {
                  offset: 1,
                  color: "#fe9a8b03"
                }
              ])
            }
          },
          {
            name: "渣收运",
            type: "line",
            data: data2,
            symbolSize: 6,
            symbol: "circle",
            // smooth: true,
            lineStyle: {
              color: "#9E87FF"
            },
            itemStyle: {
              normal: {
                color: "#9E87FF",
                borderColor: "#9E87FF"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#9E87FFb3"
                },
                {
                  offset: 1,
                  color: "#9E87FF03"
                }
              ])
            }
          }
        ]
      };
      // 绘制图表
      collectorChart1.setOption(option);

      window.addEventListener("resize", () => {
        collectorChart1.resize();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.margin-b-10 {
  margin-bottom: 10px;
}
.margin-r-10 {
  margin-right: 10px;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mying-box {
  padding: 20px;
  background: rgba(54, 59, 110, 0.3);
  border: 1px solid #3d4283;
  border-radius: 5px;
  margin-bottom: 20px;
}
.mying-box-title {
  font-size: 1.125rem;
  color: rgba(93, 229, 245, 1);
  margin-bottom: 20px;
}
.mying-box-small {
  height: 33.333vh;
  background: #363b6e;
  border: 1px solid #3d4283;
  border-radius: 5px;
}
.text-center {
  text-align: center;
}
</style>
<style lang="scss">
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
  }
}
.time-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.btn-time {
  background: radial-gradient(#3c427d, #666ca7);
  border: 1px solid #666ca7;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 0.875rem;
  margin-right: 10px;
  cursor: pointer;
}
.btn-time:hover {
  color: #ccc;
  border: 1px solid rgba($color: #666ca7, $alpha: 0.8);
}
.btn-time-active {
  background: #191c3c;
  border: 1px solid #323877;
}
.el-range-editor {
  margin: 0 20px;
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: #999;
}
.chart_height {
  width: 100%;
  height: 30vh;
}
</style>
